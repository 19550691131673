var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Browse Shipments"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":_vm.hasMultipleClients ? 5 : 6}},[_c('v-text-field',{attrs:{"label":"ID","clearable":""},model:{value:(_vm.options.params.id),callback:function ($$v) {_vm.$set(_vm.options.params, "id", $$v)},expression:"options.params.id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.hasMultipleClients ? 2 : 6}},[_c('v-checkbox',{attrs:{"label":"In Transit"},model:{value:(_vm.options.params.in_transit),callback:function ($$v) {_vm.$set(_vm.options.params, "in_transit", $$v)},expression:"options.params.in_transit"}})],1),(_vm.hasMultipleClients)?_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.clients,"item-value":"id","item-text":"title_with_id","label":"Client","clearable":""},model:{value:(_vm.options.params.client_id),callback:function ($$v) {_vm.$set(_vm.options.params, "client_id", $$v)},expression:"options.params.client_id"}})],1):_vm._e()],1)],1),_c('v-data-table',_vm._b({attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.samples",fn:function({ item }){return [_c('v-avatar',[_c('v-avatar',{attrs:{"color":!item.samples.length ? 'error' : 'primary',"size":"25"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.samples.length))])])],1)]}},{key:"item.received",fn:function({ item }){return [_c('v-icon',{attrs:{"color":item.received ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.received ? "mdi-check" : "mdi-progress-clock")+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',{staticClass:"mx-2"},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.easypost_shipment_id.length
                  ? item.tracking_number
                    ? 'primary'
                    : 'warning'
                  : ''},on:{"click":function($event){$event.stopPropagation();return (() => _vm.shippingLabelModal(item)).apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-barcode-scan ")])],1)]}}],null,true)},[_c('span',[_vm._v("Manage Shipment")])]),(
          (item.easypost_shipment_id && !item.date_shipped) ||
            (item.shipment_method_id && !item.date_shipped)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',{staticClass:"mx-2"},[_c('v-icon',_vm._g(_vm._b({staticClass:"action-icon",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return (() => _vm.resetShipping(item)).apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])],1)]}}],null,true)},[_c('span',[_vm._v("Start Shipping Over")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',{staticClass:"mx-2"},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.easypost_shipment_id.length && item.tracking_number
                  ? item.date_shipped
                    ? 'primary'
                    : 'warning'
                  : ''},on:{"click":function($event){$event.stopPropagation();return (() => _vm.shippingStatusModal(item)).apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-truck-delivery ")])],1)]}}],null,true)},[_c('span',[_vm._v("Shipping Status")])])]}}])},'v-data-table',_vm.defaultAttrs,false)),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.shipping_label.active),callback:function ($$v) {_vm.$set(_vm.shipping_label, "active", $$v)},expression:"shipping_label.active"}},[(_vm.shipping_label.item !== false)?_c('ShippingLabel',{on:{"close-dialog":() => _vm.shippingLabelModal(false),"refresh":() => _vm.refresh()},model:{value:(_vm.shipping_label.item),callback:function ($$v) {_vm.$set(_vm.shipping_label, "item", $$v)},expression:"shipping_label.item"}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.shipping_status.active),callback:function ($$v) {_vm.$set(_vm.shipping_status, "active", $$v)},expression:"shipping_status.active"}},[(_vm.shipping_status.item !== false)?_c('ShippingStatus',{on:{"close-dialog":() => _vm.shippingStatusModal(false),"refresh":() => _vm.refresh()},model:{value:(_vm.shipping_status.item),callback:function ($$v) {_vm.$set(_vm.shipping_status, "item", $$v)},expression:"shipping_status.item"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }